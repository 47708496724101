import React from "react"
import styled from "styled-components/macro"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { camelCase } from "lodash"

import { device } from "@utils/styledUtils"
import Section from "@components/atoms/Section"
import Container from "@components/atoms/Container"
import Icon from "@components/atoms/Icon"
import BlogCard from "@components/organisms/BlogCard"

const Intro = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  @media ${device.mobile} {
    flex-direction: column;
  }
`
const IntroContent = styled.div`
  flex: 1;
`
const IntroImage = styled.div`
  img {
    width: 100%;
  }
  width: 100%;
  @media ${device.tablet} {
    max-width: 300px;
    margin-left: 3em;
  }
`

const Skills = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -1em;
`
const Skill = styled.div`
  flex: 0 0 calc(25% - 2em);
  margin: 1em;
  @media ${device.mobile} {
    flex: 0 0 calc(100% - 2em);
  }
`
const SkillIcons = styled.div`
  > * {
    margin-right: 0.5em;
  }
`
const SkillTitle = styled.h3`
  font-family: "Source Code Pro", monospace;
`

const RecentPosts = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 1em;
  row-gap: 1em;
  margin-bottom: 1em;
  @media ${device.tablet} {
    grid-template-columns: repeat(6, 1fr);
  }
`

export default function IndexPage() {
  const { strapiProfile, allStrapiArticle } = useStaticQuery(graphql`
    query {
      strapiProfile {
        bio
        name
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        title
        skillGroups {
          description
          skills {
            name
            level
          }
        }
      }
      allStrapiArticle {
        nodes {
          ...ArticleCard
        }
      }
    }
  `)

  const skillTitle = (s: string) => s.replace(/,(?!.*,)/gim, " &")

  return (
    <>
      <Section light>
        <Container>
          <Intro>
            <IntroContent>
              <h1>{strapiProfile.name}</h1>
              <h3>{strapiProfile.title}</h3>
              <p>{strapiProfile.bio}</p>
            </IntroContent>
            <IntroImage>
              <GatsbyImage
                alt={`image of ${strapiProfile.name}`}
                image={
                  strapiProfile.image.localFile.childImageSharp.gatsbyImageData
                }
                loading="eager"
              />
            </IntroImage>
          </Intro>
        </Container>
      </Section>
      <Section>
        <Container>
          <h2>What I do</h2>
          <Skills>
            {strapiProfile.skillGroups.map(group => (
              <Skill key={`skill-group-${group.id}`}>
                <SkillIcons>
                  {group.skills.map(skill => (
                    <Icon
                      name={camelCase(skill.name.toLowerCase())}
                      size={30}
                      key={skill.name}
                    />
                  ))}
                </SkillIcons>
                <SkillTitle>
                  {skillTitle(group.skills.map(skill => skill.name).join(", "))}
                </SkillTitle>
                <p>{group.description}</p>
              </Skill>
            ))}
          </Skills>
        </Container>
      </Section>
      {/* <Section>
        <Container>
          <h2>Featured Projects</h2>
        </Container>
      </Section>
      <Section>
        <Container>
          <h2>Testimonials</h2>
        </Container>
      </Section> */}
      <Section>
        <Container>
          <h2>Latest Blog Posts</h2>
          <RecentPosts>
            {allStrapiArticle.nodes.map(post => (
              <BlogCard key={post.id} post={post} />
            ))}
          </RecentPosts>
        </Container>
      </Section>
    </>
  )
}
